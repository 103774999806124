// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useParallax } from "react-scroll-parallax";
// @ts-ignore
import docker_icon from "../assets/docker_icon.png";
// @ts-ignore
import github_icon from "../assets/github_icon.png";
// @ts-ignore
import linux_icon from "../assets/linux_icon.png";
// @ts-ignore
import postman_icon from "../assets/postman_icon.png";
import firebase_icon from "../assets/firebase_icon.png";
import SkillBar from "../components/SkillBar";


const currentYear = new Date().getFullYear();

const skills = [
  { name: "Java", level: 80 },
  { name: "Python", level: 95 },
  { name: "C++", level: 70 },
  { name: "HTML", level: 80 },
  { name: "CSS", level: 70 },
  { name: "JavaScript", level: 90 },
  { name: "TypeScript", level: 90 },
  { name: "React", level: 90 },
  { name: "React Native", level: 90 },
  { name: "Flask", level: 85 },
  { name: "Django", level: 70 },
  { name: "SQL", level: 80 },

  // Add more skills as needed
];
const HomePage = () => {
  const winH = window.innerHeight;

  const parallax = useParallax({
    startScroll: winH * 0.08,
    endScroll: winH * 0.5,
    translateX: ["-100", "0", "easeOut"],
  });

  const docker_icon_anime = useParallax({
    startScroll: winH * 0.15,
    endScroll: winH * 0.6,
    translateX: ["-200", "0", "easeOut"],
  });

  const github_icon_anime = useParallax({
    startScroll: winH * 0.25,
    endScroll: winH * 0.7,
    translateX: ["-200", "0", "easeOut"],
  });

  const linux_icon_anime = useParallax({
    startScroll: winH * 0.35,
    endScroll: winH * 0.8,
    translateX: ["-200", "0", "easeOut"],
  });
  const postman_icon_anime = useParallax({
    startScroll: winH * 0.45,
    endScroll: winH * 0.9,
    translateX: ["-200", "0", "easeOut"],
  });
  const firebase_icon_anime = useParallax({
    startScroll: winH * 0.55,
    endScroll: winH * 1,
    translateX: ["-200", "0", "easeOut"],
  });

  const [visibleDockerExperiences, setVisibleDockerExpereinces] =
    useState(false);
  const [visibleGithubExperiences, setVisibleGithubExpereinces] =
    useState(false);
  const [visibleLinuxExperiences, setVisibleLinuxExpereinces] = useState(false);
  const [visiblePostmanExperiences, setVisiblePostmanExpereinces] =
    useState(false);
  const [visibleFirebaseExperiences, setVisibleFirebaseExpereinces] =
    useState(false);

  const handleDockerClick = () => {
    setVisibleDockerExpereinces(!visibleDockerExperiences);
  };
  const handleGithubClick = () => {
    setVisibleGithubExpereinces(!visibleGithubExperiences);
  };
  const handleLinuxClick = () => {
    setVisibleLinuxExpereinces(!visibleLinuxExperiences);
  };
  const handlePostmanClick = () => {
    setVisiblePostmanExpereinces(!visiblePostmanExperiences);
  };
  const handleFirebaseClick = () => {
    setVisibleFirebaseExpereinces(!visibleFirebaseExperiences);
  };
  
  const [visible, setVisible] = useState({});

  const [experiencesData, setExperiencesData] = useState([]);
  
  const handleMouseEnter = (title) => {
    setVisible((prevState) => ({ ...prevState, [title]: true }));
  };

  const handleMouseLeave = (title) => {
    setVisible((prevState) => ({ ...prevState, [title]: false }));
  };

  useEffect(() => {
    fetch("/assets/experience.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to load projects.json");
        }
        return response.json();
      })
      .then((data) => setExperiencesData(data.experience || []))
      .catch((error) => console.error("Error loading projects:", error));
  }, []);

  const renderExperience = (experience) => {
    const yearsOfExperience = currentYear - experience.startYear;
    return (
      <>
      <li
        key={experience.title}
        onMouseEnter={() => handleMouseEnter(experience.title)}
        onMouseLeave={() => handleMouseLeave(experience.title)}
      >
        - {experience.title}
      </li>
      {visible[experience.title] && <p className="experienceDesc">+ {yearsOfExperience} years {experience.desc}</p>}
      </>
    );
  };

  return (
    <div className="home">
      <div className="Intro">Hello, I'm Keizo.</div>
      <div className="Intro2">
        <h1>Versatile Software Developer</h1>
      </div>
      <div className="Intro3">
        <h1
          // @ts-ignore
          ref={parallax.ref}
        >
          Skills & Experience
        </h1>
      </div>
      <div className="Intro4">
        <div className="Intro4_tools">
          {/* <h2>Tools</h2> */}
          <img
            onClick={handleDockerClick}
            ref={docker_icon_anime.ref}
            src={docker_icon}
            alt="docker"
          />
          {visibleDockerExperiences ? <>Docker</> : <></>}
          <img
            onClick={handleGithubClick}
            ref={github_icon_anime.ref}
            src={github_icon}
            alt="github"
          />
          {visibleGithubExperiences ? <>GitHub</> : <></>}
          <img
            onClick={handleLinuxClick}
            ref={linux_icon_anime.ref}
            src={linux_icon}
            alt="linux"
          />
          {visibleLinuxExperiences ? <>Linux (Ubuntu)</> : <></>}
          <img
            onClick={handlePostmanClick}
            ref={postman_icon_anime.ref}
            src={postman_icon}
            alt="postman"
          />
          {visiblePostmanExperiences ? <>Postman</> : <></>}
          <img
            onClick={handleFirebaseClick}
            ref={firebase_icon_anime.ref}
            src={firebase_icon}
            alt="firebase"
          />
          {visibleFirebaseExperiences ? <>Firebase</> : <></>}
        </div>
        <div className="Intro4_skills">
          {/* <h2>skills</h2> */}
          {skills.map((skill) => (
            <SkillBar key={skill.name} skill={skill.name} level={skill.level} />
          ))}
        </div>
      </div>
      <div className="Intro5">
        <div className="Intro5_image"></div>
        <div className="Intro5_text">
          <div className="about_me">
            <h1>About Me</h1>
            <p>
              I currently locate at Metro Vancouver Area after graduating from
              the University of British Columbia Okanagan with Bsc(Honours) in
              Computer Science. Before moving to Kelowna, I was in Victoria, BC
              as a student at Camosun College (many amazing instructors:).
            </p>
            <p>
              During my school time at UBCO, I worked as a Barista and also a
              Store Manager at Blenz Coffee in different store locations. This
              experiences were not only for support my daily expenses as a broke
              student, but also gain real Canadian working experiences which
              expanded a lot of my soft skills side.
            </p>
            <p>
              My passion is learning new stuff in general, which is not limited
              to technology. Starting from scratch has been my habit since I was
              a kid. I started learning how to play the drums, how to speak
              English (I'm Japanese btw), how to program, how to compose music
              in DAW, and so on. I can reframe this in different ways, but for
              my developer side here's what I can do:
            </p>
            <ul>
              {experiencesData.map(renderExperience)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
